import { affirmativeField, emailAnswerButton, negativeField } from "consts";
import { html as htmlBeautify } from "js-beautify";
import { FieldOptionType, HTMLInputDateTimeType } from "models";
import { RefObject } from "react";
import { validateDate } from "./";

export const arrayEquals = <T extends any>(
  first: Array<T>,
  second: Array<T>
) => {
  return (
    first.length === second.length &&
    first.every((val, index) => val === second[index])
  );
};

export const cliboard = (clip?: string) => {
  return navigator.clipboard.writeText(clip || "");
};

export const debounce = (callback: Function, timeout = 300) => {
  let timer: NodeJS.Timeout;

  const cancel = () => clearTimeout(timer);

  const debounced = (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), timeout);
  };

  debounced.cancel = cancel;

  return debounced;
};

export const exitElement =
  (ref: RefObject<HTMLElement>, callback?: () => void) => (e: Event) => {
    if (
      e.target !== ref?.current &&
      !ref?.current?.contains(e.target as Node)
    ) {
      callback?.();
    }
  };

export const extractFileContent = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.onload = async ({ target }) =>
        resolve(target?.result?.toString() || "");
    } catch (error) {
      return reject(new Error("Erro ao extrair texto do arquivo"));
    }
    reader.readAsText(file);
  });
};

export const formatText = (text: string) => {
  return text.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, "\n");
};

export const generateUID = () => {
  const numToBase16 = (num: number) => Math.floor(num).toString(16);
  return (
    numToBase16(Date.now() / 1000) +
    " ".repeat(16).replace(/./g, () => numToBase16(Math.random() * 16))
  );
};

export const getFirstAndLastWord = (name = "") => {
  if (!name) return name;
  const names = name.trim().split(" ");
  if (names.length > 1) return `${names[0]} ${names[names.length - 1]}`;
  return name;
};

export const htmlFormater = (html = "") => {
  const currentHTML = html
    .replace(/>/g, ">\n")
    .replace(/<\//g, "\n</")
    .replace(/^\s*\n/gm, "");
  return htmlBeautify(currentHTML);
};

export const getHTMLEmailAnswerButton = (args: object) => {
  let html = emailAnswerButton;
  Object.entries(args).forEach(([key, value = ""]) => {
    html = html.replaceAll(`{${key}}`, value);
  });
  return html;
};

export const getNextFieldName = (listFields: FieldOptionType<string>[]) => {
  const fieldName = "field";

  if (listFields.length === 0) return `${fieldName}01`;

  const fields = listFields
    .map((val) =>
      Number(
        val.fieldName.replace(fieldName, "").replace("{{", "").replace("}}", "")
      )
    )
    .sort((a, b) => a - b);

  return `${fieldName}${(fields[fields.length - 1] + 1)
    .toString()
    .padStart(2, "0")}`;
};

export const insertInCursorPointer =
  (
    ref: RefObject<HTMLTextAreaElement | HTMLInputElement> | HTMLTextAreaElement | HTMLInputElement,
    addBraces?: boolean
  ) =>
  (text: string) => {
    let input: HTMLInputElement | HTMLTextAreaElement | null;

    if (ref instanceof HTMLInputElement || ref instanceof HTMLTextAreaElement) {
      input = ref;
    } else {
      input = ref.current;
    }

    if (input) {
      const event = new Event("change", { bubbles: true, cancelable: true });
      const { value, selectionStart, selectionEnd } = input;
      const previousText = value.slice(0, selectionStart || value.length);
      const nextText = value.slice(selectionEnd || value.length);
      const currentText = addBraces ? `{${text}}` : text;
      input.value = `${previousText}${currentText}${nextText}`;
      input.dispatchEvent(event);
    }
  };

export const isRangeAccepted = (
  interval: Duration,
  type: HTMLInputDateTimeType
) => {
  const { years = 0, months = 0, days = 0 } = interval;
  switch (type) {
    case "month": {
      if (years > 5 || (years === 5 && (months > 0 || days > 0))) return false;
      return true;
    }
    default: {
      if (years > 0) return false;
      else if (months > 2 || (months === 2 && days > 0)) return false;
      return true;
    }
  }
};

export const objectToFormData = (item: Object) => {
  const formData = new FormData();
  Object.entries(item).map(([key, value]) => formData.append(key, value));
  return formData;
};

export const timeResolver = (
  value: string,
  options?: Intl.DateTimeFormatOptions
) => {
  if (validateDate(value))
    return new Date(value).toLocaleTimeString("pt-BR", options);
  return value;
};

export const recalculateFieldList = (
  message: string,
  list: FieldOptionType<string>[],
  hasAnswer?: boolean
) => {
  const fields = message.match(/(?<=\{{)(.*?)(?=\}})/gim) ?? [];
  let newList: FieldOptionType<string>[] = list;

  fields.forEach((field) => {
    if (!newList.some(({ fieldValue }) => fieldValue === `{${field}}`))
      newList.push({
        _id: generateUID(),
        fieldValue: `{${field}}`,
        fieldName: getNextFieldName(newList),
      });
  });

  return hasAnswer ? recalculateURLFieldList(newList) : newList;
};

export const recalculateURLFieldList = (list: FieldOptionType<string>[]) => {
  if (!list.some(({ fieldValue }) => fieldValue === affirmativeField)) {
    list.push({
      _id: generateUID(),
      fieldValue: affirmativeField,
      fieldName: getNextFieldName(list),
    });
  }

  if (!list.some(({ fieldValue }) => fieldValue === negativeField)) {
    list.push({
      _id: generateUID(),
      fieldValue: negativeField,
      fieldName: getNextFieldName(list),
    });
  }

  return list;
};
