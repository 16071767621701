import { ReactNode } from "react";
import {
  Container,
  IconContent,
  Label,
  NavItemStyleType,
  Title,
  TitleContent,
} from "./styles";

type NavItemType = NavItemStyleType & {
  label?: string;
  name: string;
  icon: ReactNode;
};

const NavItem = ({ icon, label, name, justIcon, isActive }: NavItemType) => {
  return (
    <Container justIcon={justIcon} hasLabel={!!label} isActive={isActive}>
      <IconContent title={name} isActive={isActive}>
        {icon}
      </IconContent>
      {!justIcon && (
        <TitleContent>
          {label && <Label>{label}</Label>}
          <Title>{name}</Title>
        </TitleContent>
      )}
    </Container>
  );
};

export default NavItem;
