import Dialog from "components/dialog";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

type DialogDataType = {
  title: string;
  description: string;
  callback: () => void;
};

type DialogContextType = {
  confirm: (data: DialogDataType) => void;
};

type DialogProviderType = {
  children: ReactNode;
};

const DialogContext = createContext<DialogContextType>({} as DialogContextType);

const DialogProvider = ({ children }: DialogProviderType) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<DialogDataType>();

  const confirm = useCallback((data: DialogDataType) => {
    setData(data);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(undefined);
  }, []);

  const handleConfirm = useCallback(() => {
    data?.callback?.();
    handleClose();
  }, [data, handleClose]);

  return (
    <DialogContext.Provider value={{ confirm }}>
      {children}
      {data && (
        <Dialog
          show={open}
          title={data?.title}
          description={data?.description}
          onConfirm={handleConfirm}
          onCancel={handleClose}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);

export default DialogProvider;
