import { ReactNode } from "react";
import { AvatarImage, AvatarPros, Container } from "./styles";

export type AvatarImageProps = AvatarPros & {
  src?: string | any;
  icon?: ReactNode;
};

const Avatar = ({ src, icon, ...args }: AvatarImageProps) => {
  return (
    <Container {...args}>
      {src ? <AvatarImage alt="Avatar" src={src} /> : icon}
    </Container>
  );
};

export default Avatar;
