import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

type RequireAuthType = { children: JSX.Element };

const RequireAuth = ({ children }: RequireAuthType) => {
  const [redirect, setRedirect] = useState(false);
  const { hasAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const callAsyncFunction = async () => {
      setRedirect(!(await hasAuthenticated()));
    };

    callAsyncFunction();
  }, [hasAuthenticated]);

  if (redirect) {
    return <Navigate to="/authentication" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
