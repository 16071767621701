import { i18nLanguage, languages } from "consts";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const commonsPT = require("./pt/commons.json");
const commonsEN = require("./en/commons.json");

const componentsPT = require("./pt/components.json");
const componentsEN = require("./en/components.json");

const authPT = require("./pt/auth.json");
const authEN = require("./en/auth.json");

const profilePT = require("./pt/profile.json");
const profileEN = require("./en/profile.json");

const journeyPT = require("./pt/journey.json");
const journeyEN = require("./en/journey.json");

const companyPT = require("./pt/company.json");
const companyEN = require("./en/company.json");

const reportPT = require("./pt/report.json");
const reportEN = require("./en/report.json");

const exportPT = require("./pt/export.json");
const exportEN = require("./en/export.json");

const planPT = require("./pt/plan.json");
const planEN = require("./en/plan.json");

const configsPT = require("./pt/configs.json");
const configsEN = require("./en/configs.json");

const resources = {
  pt: {
    commons: commonsPT,
    components: componentsPT,
    auth: authPT,
    profile: profilePT,
    journey: journeyPT,
    company: companyPT,
    report: reportPT,
    export: exportPT,
    plan: planPT,
    configs: configsPT,
  },
  en: {
    commons: commonsEN,
    components: componentsEN,
    auth: authEN,
    profile: profileEN,
    journey: journeyEN,
    company: companyEN,
    report: reportEN,
    export: exportEN,
    plan: planEN,
    configs: configsEN,
  },
};

const languagePathDetector = {
  name: "languagePathDetector",
  lookup() {
    const path = window.location.pathname;
    const firstItemPath = path?.split("/")?.[0 || 1];
    const defaultLanguage = window.localStorage.getItem(i18nLanguage) || "";
    return languages.some((language) => language === firstItemPath)
      ? firstItemPath
      : defaultLanguage;
  },
};

const detector = new LanguageDetector();
detector.addDetector(languagePathDetector);

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    debug: false,
    ns: [
      "commons",
      "components",
      "auth",
      "profile",
      "journey",
      "report",
      "export",
      "plan",
      "configs",
    ],
    defaultNS: "commons",
    fallbackNS: "commons",
    fallbackLng: languages,
    detection: {
      order: ["languagePathDetector"],
      lookupLocalStorage: i18nLanguage,
    },
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18next;
