import HeaderProvider from "providers/header";
import JourneyProvider from "providers/journey";
import ModelProvider from "providers/configs";
import PlansProvider from "providers/plan";
import SectorsProvider from "providers/sectors";
import Content from "./content";
import Header from "./header";
import SideBar from "./sideBar";
import { LayoutContainer, LayoutContent } from "./styles";

const Layout = () => {
  return (
    <HeaderProvider>
      <PlansProvider>
        <SectorsProvider>
          <JourneyProvider>
            <ModelProvider>
              <LayoutContainer>
                <SideBar />
                <LayoutContent>
                  <Header />
                  <Content />
                </LayoutContent>
              </LayoutContainer>
            </ModelProvider>
          </JourneyProvider>
        </SectorsProvider>
      </PlansProvider>
    </HeaderProvider>
  );
};

export default Layout;
