import axios, { AxiosRequestConfig } from "axios";
import { clearLocalStorage, ls, tokenKey } from "utils";

export const awsService = axios.create({
  baseURL: process.env.REACT_APP_AWS_BASE_URL,
  timeout: 120000,
});

const CancelToken = axios.CancelToken;
const pendingRequest = new Map();

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 120000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

service.interceptors.request.use(
  (request) => {
    const currentToken = ls.getItem(tokenKey);
    if (currentToken) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${currentToken}`,
      };
    }

    const requestKey = getReqKey(request);

    if (pendingRequest.has(requestKey)) removeReqKey(requestKey);
    else
      request.cancelToken = new CancelToken((cancel) =>
        pendingRequest.set(requestKey, cancel)
      );

    return request;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => {
    removeReqKey(getReqKey(response.config));
    return response;
  },
  (error) => {
    removeReqKey(getReqKey(error.config));

    if (axios.isCancel(error)) return new Promise(() => {});

    if (error?.response?.status === 419) {
      const currentToken = ls.getItem(tokenKey);
      if (currentToken) {
        clearLocalStorage();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

const getReqKey = (config: AxiosRequestConfig) =>
  `${config?.method}-${config?.url}}`;

const removeReqKey = (key: string) => {
  const cancelToken = pendingRequest.get(key);
  cancelToken?.(key);
  pendingRequest.delete(key);
};

export default service;
