import { ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";
import ModalHeader from "./header";
import { Container, ModalContent, ModalFooter, ModalMain } from "./styles";

interface ModalProps {
  show: boolean;
  size?: 'small' | 'medium' | 'large';
  children: ReactNode;
}

const Modal = ({ show, children, size='small', ...args }: ModalProps) => {
  const modalRoot = useMemo(() => document.getElementById("modal"), []);

  if (!modalRoot || !show) return <></>;

  return createPortal(
    <Container onClick={(e) => e.stopPropagation()} {...args}>
      <ModalContent size={size}>{children}</ModalContent>
    </Container>,
    modalRoot
  );
};

export { ModalHeader, ModalMain, ModalFooter };
export default Modal;
