export const tokenKey = process.env.REACT_APP_TOKEN_KEY || "";
export const companyKey = process.env.REACT_APP_COMPANY_KEY || "";
export const userKey = process.env.REACT_APP_USER_KEY || "";

export const ls = localStorage;

export const clearLocalStorage = () => {
  ls.removeItem(tokenKey);
  ls.removeItem(companyKey);
  ls.removeItem(userKey);
};
