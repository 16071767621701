import { useTranslation } from "react-i18next";
import Button from "../button";
import Modal, { ModalMain } from "../modal";
import { CustomModalFooter, CustomModalHeader } from "./styles";

export type DialogType = {
  show: boolean;
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const Dialog = ({
  show,
  title,
  description,
  onCancel,
  onConfirm,
}: DialogType) => {
  const { t } = useTranslation("components");

  return (
    <Modal show={show}>
      <CustomModalHeader title={title} onClose={onCancel} />
      <ModalMain>
        <p>{description}</p>
      </ModalMain>
      <CustomModalFooter>
        <Button outline onClick={onCancel}>
          {t("cancel")}
        </Button>
        <Button color="danger" onClick={onConfirm}>
          {t("confirm")}
        </Button>
      </CustomModalFooter>
    </Modal>
  );
};

export default Dialog;
