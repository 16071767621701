import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactNode } from "react";
import IconButton from "../iconButton";
import { HeaderContainer } from "./styles";

interface ModalHeaderProps {
  children?: ReactNode;
  title?: string;
  onClose: () => void;
}

const ModalHeader = ({
  children,
  title,
  onClose,
  ...args
}: ModalHeaderProps) => {
  return (
    <HeaderContainer {...args}>
      {children || <h3>{title}</h3>}
      <IconButton color="text" icon={<CloseIcon />} onClick={onClose} />
    </HeaderContainer>
  );
};

export default ModalHeader;
