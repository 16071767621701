import styled from "styled-components";
import Button from "../button";

export type ToastOptionTypes = "success" | "error" | "warning" | "info";

export type ToastStyleType = {
  type?: ToastOptionTypes;
};

const getColorByType = (type: string) => {
  switch (type) {
    case "error":
      return "var(--red)";
    case "warning":
      return "var(--yellow)";
    case "info":
      return "var(--cyan)";
    default:
      return "var(--green)";
  }
};

export const Container = styled.div<ToastStyleType>`
  width: 30rem;
  padding: 1.6rem;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 1.6rem;

  border-radius: 0.4rem;
  box-shadow: 0 0.6rem 1.2rem -0.6rem var(--box-shadow),
    0 0.8rem 2.4rem -0.4rem var(--box-shadow);

  border-left: 0.4rem solid ${({ type }) => type && getColorByType(type)};

  div {
    cursor: pointer;
    font-size: 18px;
  }
`;

export const IconContent = styled.div<ToastStyleType>`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ type }) => type && getColorByType(type)};
`;

export const InfoContent = styled.div`
  flex: 1;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const CustomButton = styled(Button)`
  padding: 0.6rem 1.6rem;
  margin-top: 1.6rem;
  background-color: var(--neutral);
  color: var(--text-title);

  &:hover,
  &:focus {
    background-color: var(--gray);
  }
`;
