import useMap from "hooks/map";
import { LoadLayoutType, SegmentsLoadLayoutType, GroupType, ContatoType, DenyType } from "models";
import { LoadLayoutErrors } from "pages/config/errors";
import { useAuth } from "providers/auth";
import { useJourney } from "providers/journey";
import { useToast } from "providers/toast";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import * as LoadLayoutService from "services/configs";

type LoadLayoutContextType = {
  loadLayouts?: Map<string, LoadLayoutType>;
  setLoadLayouts: Dispatch<
    SetStateAction<Map<string, LoadLayoutType> | undefined>
  >;
  addLoadLayout: (key: string, value: LoadLayoutType) => void;
  editLoadLayout: (key: string, value: LoadLayoutType) => void;
  removeLoadLayout: (key: string) => void;
  clearLoadLayout: () => void;
  getCurrentLoadLayout: (journeyId: string) => LoadLayoutType | undefined;
  segmentsLoadLayouts?: Map<string, SegmentsLoadLayoutType>;
  setSegmentsLoadLayouts: Dispatch<
    SetStateAction<Map<string, SegmentsLoadLayoutType> | undefined>
  >;
  addSegmentsLoadLayout: (key: string, value: SegmentsLoadLayoutType) => void;
  editSegmentsLoadLayout: (key: string, value: SegmentsLoadLayoutType) => void;
  removeSegmentsLoadLayout: (key: string) => void;
  clearSegmentsLoadLayout: () => void;
  getSegmentsLoadLayout: (contactModelId: string) => SegmentsLoadLayoutType[] | undefined;
  groups?: Map<string, GroupType>;
  setGroups: Dispatch<
    SetStateAction<Map<string, GroupType> | undefined>
  >;
  addGroup: (key: string, value: GroupType) => void;
  editGroup: (key: string, value: GroupType) => void;
  removeGroup: (key: string) => void;
  clearGroup: () => void;
  getCurrentGroup: (journeyId: string) => GroupType | undefined;  
  contacts?: Map<string, ContatoType>;
  setContacts: Dispatch<
    SetStateAction<Map<string, ContatoType> | undefined>
  >;
  addContact: (key: string, value: ContatoType) => void;
  editContact: (key: string, value: ContatoType) => void;
  removeContact: (key: string) => void;
  clearContact: () => void;
  getCurrentContact: (journeyId: string) => ContatoType | undefined;  
  denys?: Map<string, DenyType>;
  setDenys: Dispatch<
    SetStateAction<Map<string, DenyType> | undefined>
  >;
  addDeny: (key: string, value: DenyType) => void;
  editDeny: (key: string, value: DenyType) => void;
  removeDeny: (key: string) => void;
  clearDeny: () => void;
  getCurrentDeny: (journeyId: string) => DenyType | undefined;  
};

type LoadLayoutProviderType = {
  children: ReactNode;
  journeyId?: string;
};

const LoadLayoutContext = createContext({} as LoadLayoutContextType);

const LoadLayoutProvider = ({ children }: LoadLayoutProviderType) => {

  const [loadLayouts, loadLayoutActions] = useMap<string, LoadLayoutType>();
  const [segmentsLoadLayouts, segmentsLoadLayoutActions] = useMap<string, SegmentsLoadLayoutType>();
  const [groups, groupActions] = useMap<string, GroupType>();
  const [contacts, contactActions] = useMap<string, ContatoType>();
  const [denys, denyActions] = useMap<string, DenyType>();

  const { error, warning } = useToast();
  const { t } = useTranslation();
  const { getJourney } = useJourney();
  const { user } = useAuth();

  const errorsResolver = useMemo(
    () => new LoadLayoutErrors({ error, warning }, t),
    [error, warning, t]
  );

  const companyId = useMemo(() => user?.selectedCompany._id, [user]);
  const organizationId = useMemo(() => user?.selectedOrganization, [user]);

  useEffect(() => {
    if (companyId && organizationId) {
      const callAsyncFunction = async () => {
        await Promise.all([
          LoadLayoutService.getLoadLayouts(companyId, organizationId).then(({ data }) =>
            loadLayoutActions.set(
              new Map(
                data.layouts.map((template) => [template._id, template])
              )
            )
          ),
          LoadLayoutService.getSegmentsLoadLayouts(companyId, organizationId).then(({ data }) =>   {         
            segmentsLoadLayoutActions.set(
              new Map(data.segments.map((segment) => [segment._id, segment]))
            )
          }),
          LoadLayoutService.getGroups(companyId, organizationId).then(({ data }) =>
            groupActions.set(
              new Map(
                data.groups.map((group) => [group._id, group])
              )
            )
          ),
          LoadLayoutService.getContacts(companyId, organizationId).then(({ data }) =>
            contactActions.set(
              new Map(data.contactLists.map((contact) => [contact._id, contact]))
            )
          ),
          LoadLayoutService.getDenys(companyId, organizationId).then(({ data }) => {
            denyActions.set(
              new Map(data.denyLists.map((deny) => [deny._id, deny]))
            )
          }),
        ]).catch(errorsResolver.defaultError);
      };
      callAsyncFunction();
    }
  }, [companyId, errorsResolver, loadLayoutActions, segmentsLoadLayoutActions, groupActions, contactActions, denyActions, organizationId]);

  const getCurrentLoadLayout = useCallback(
    (journeyId: string) => {
      const currentJourney = getJourney(journeyId);
      const currentLoadLayout = currentJourney?.settings.parserCSV?.value;
      if (currentLoadLayout) return loadLayouts?.get(currentLoadLayout);
    },
    [loadLayouts, getJourney]
  );

  const getSegmentsLoadLayout = useCallback(
    (journeyId: string) => {
      const currentJourney = getJourney(journeyId);
      const currentLoadLayout = currentJourney?.settings.parserCSV?.value;
      if (currentLoadLayout)
        return Array.from(segmentsLoadLayouts?.values() ?? [])?.filter(
          (group) => group.id_loadLayout === currentLoadLayout
        );
    },
    [segmentsLoadLayouts, getJourney]
  );

  const getCurrentGroup = useCallback(
    (journeyId: string) => {
      const currentJourney = getJourney(journeyId);
      const currentGroup = currentJourney?.settings.parserCSV?.value;
      if (currentGroup) return groups?.get(currentGroup);
    },
    [groups, getJourney]
  );
  
  const getCurrentContact = useCallback(
    (journeyId: string) => {
      const currentJourney = getJourney(journeyId);
      const currentContact = currentJourney?.settings.parserCSV?.value;
      if (currentContact) return contacts?.get(currentContact);
    },
    [contacts, getJourney]
  );

  const getCurrentDeny = useCallback(
    (journeyId: string) => {
      const currentJourney = getJourney(journeyId);
      const currentDeny = currentJourney?.settings.parserCSV?.value;
      if (currentDeny) return denys?.get(currentDeny);
    },
    [denys, getJourney]
  );

  return (
    <LoadLayoutContext.Provider
      value={{
        loadLayouts,
        setLoadLayouts: loadLayoutActions.set,
        addLoadLayout: loadLayoutActions.add,
        editLoadLayout: loadLayoutActions.edit,
        removeLoadLayout: loadLayoutActions.remove,
        clearLoadLayout: loadLayoutActions.clear,
        getCurrentLoadLayout,
        segmentsLoadLayouts,
        setSegmentsLoadLayouts: segmentsLoadLayoutActions.set,
        addSegmentsLoadLayout: segmentsLoadLayoutActions.add,
        editSegmentsLoadLayout: segmentsLoadLayoutActions.edit,
        removeSegmentsLoadLayout: segmentsLoadLayoutActions.remove,
        clearSegmentsLoadLayout: segmentsLoadLayoutActions.clear,
        getSegmentsLoadLayout,
        groups,
        setGroups: groupActions.set,
        addGroup: groupActions.add,
        editGroup: groupActions.edit,
        removeGroup: groupActions.remove,
        clearGroup: groupActions.clear,
        getCurrentGroup,
        contacts,
        setContacts: contactActions.set,
        addContact: contactActions.add,
        editContact: contactActions.edit,
        removeContact: contactActions.remove,
        clearContact: contactActions.clear,
        getCurrentContact,
        denys,
        setDenys: denyActions.set,
        addDeny: denyActions.add,
        editDeny: denyActions.edit,
        removeDeny: denyActions.remove,
        clearDeny: denyActions.clear,
        getCurrentDeny,
      }}
    >
      {children}
    </LoadLayoutContext.Provider>
  );
};

export const useLoadLayout = () => useContext(LoadLayoutContext);

export default LoadLayoutProvider;
