export type MaskType =
  | "phone"
  | "unformattedPhone"
  | "zipCode"
  | "cpf"
  | "cnpj"
  | "birthDate";

const patternToMask = (value: string, pattern: string) => {
  const currentValue = value.replace(/\D/g, "");
  let position = 0;
  return pattern
    .replace(/(#{1,})/g, (element) => {
      const length = element.length;
      const result = currentValue.slice(position, position + length);
      position += length;
      return result;
    })
    .replace(/(\W{1,})$/, "");
};

export const applyMask = (value: string, mask: MaskType) => {
  if (!value) return value;

  switch (mask) {
    case "phone":
      return patternToMask(value, "(##) #####-####");
    case "unformattedPhone":
      return patternToMask(value, "###############");
    case "zipCode":
      return patternToMask(value, "#####-###");
    case "cpf":
      return patternToMask(value, "###.###.###-##");
    case "cnpj":
      return patternToMask(value, "##.###.###/####-##");
    case "birthDate":
      return patternToMask(value, "##/##/####");
    default:
      return value;
  }
};
