import Select from "components/select";
import styled from "styled-components";

export const Container = styled.li`
  width: 100%;
  height: 5.2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.75rem 0.4rem;
  border-radius: 0.4rem;

  &:hover {
    cursor: pointer;
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
  }
`;

export const IconContent = styled.div`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-400);
`;

export const SectorContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  max-width: 85%;
  padding-right: 0.9rem;
`;

export const NavSelect = styled(Select)`
  > div:first-child {
    border: none;
    padding: initial;

    p {
      margin-top: 1.5rem;
    }
  }
`;

export const SelectLabel = styled.h3`
  font-size: 1rem;
  color: var(--neutral-400);
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2rem;

  position: absolute;
  left: 0;
  top: 0.5rem;
  z-index: 1;
`;
