import { ReactComponent as ConsumptionIcon } from "assets/sidebar/consumption.svg";
import {
  Container,
  IconContent,
  LoadingBar,
  NavPercentStyleType,
  PlanInfoContainer,
  SubInfo,
  Title,
} from "./styles";

type NavPercentType = NavPercentStyleType & {
  name: string;
  justIcon?: boolean;
};

const NavPercent = ({
  consumption,
  name,
  justIcon,
  isActive,
}: NavPercentType) => {
  let statusColor = "#38B878";
  if (Number(consumption) > 50 && Number(consumption) <= 80) {
    statusColor = "#F9D65B";
  } else if (Number(consumption) > 80) {
    statusColor = "#F9675B";
  }

  return (
    <Container isActive={isActive}>
      <IconContent isActive={isActive}>
        <ConsumptionIcon />
      </IconContent>
      {!justIcon && (
        <PlanInfoContainer>
          <Title>{name}</Title>
          <LoadingBar
            consumption={consumption}
            color={statusColor}
          ></LoadingBar>
          <SubInfo>{consumption}%</SubInfo>
        </PlanInfoContainer>
      )}
    </Container>
  );
};

export default NavPercent;
