import { useHeader } from "providers/header";
import { Children, cloneElement, isValidElement } from "react";
import { ActionsContent, HeaderContainer, InfoContent } from "./styles";

const MainHeader = () => {
  const { header } = useHeader();

  return (
    <HeaderContainer>
      {header?.icon}
      <InfoContent>
        <h1>{header?.title}</h1>
        {header?.description && <p>{header?.description}</p>}
      </InfoContent>
      {header?.actions && (
        <ActionsContent>
          {Children.map(header?.actions, (child, index) => {
            if (isValidElement(child))
              return cloneElement(child, { key: index });
          })}
        </ActionsContent>
      )}
    </HeaderContainer>
  );
};

export default MainHeader;
