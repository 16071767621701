import useMap from "hooks/map";
import { JourneyType } from "models";
import { JourneyErrors } from "pages/journey/errors";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import * as JourneyService from "services/journey";

type JourneyContextType = {
  journeys?: Map<string, JourneyType>;
  getJourney: (_id: string) => JourneyType | undefined;
  addJourney: (journey: JourneyType) => void;
  editJourney: (journey: JourneyType) => void;
  removeJourney: (_id: string) => void;
  publishJourney: (
    name: string,
    description: string,
    journeyId: string,
    isActive: boolean
  ) => Promise<void>;
};

type JourneyProviderType = {
  children: ReactNode;
};

const JourneyContext = createContext({} as JourneyContextType);

const JourneyProvider = ({ children }: JourneyProviderType) => {
  const [journeys, { set, add, remove }] = useMap<string, JourneyType>();
  const { user } = useAuth();
  const { error, warning, success } = useToast();
  const { t } = useTranslation("journey");

  const errorsResolver = useMemo(
    () => new JourneyErrors({ error, warning }, t),
    [error, warning, t]
  );

  const SelectedOrganization = useMemo(
    () => user?.selectedOrganization,
    [user]
  );

  const getJourneys = useCallback(async () => {
    await JourneyService.getJourneys().then(({ data }) =>
      set(new Map(data.map((journey) => [journey._id, journey])))
    );
  }, [set]);

  useEffect(() => {
    if (SelectedOrganization) getJourneys();
  }, [SelectedOrganization, getJourneys]);

  const getJourney = useCallback(
    (_id: string) => journeys?.get(_id),
    [journeys]
  );

  const addJourney = useCallback(
    (journey: JourneyType) => add(journey._id, journey),
    [add]
  );

  const editJourney = addJourney.bind({});

  const removeJourney = useCallback((_id: string) => remove(_id), [remove]);

  const publishJourney = useCallback(
    async (
      name: string,
      description: string,
      journeyId: string,
      isActive: boolean
    ) => {
      await JourneyService.publishJourney(journeyId, {
        name,
        description,
        isActive,
      })
        .then(({ data }) => {
          success({
            description: t("alerts.journeyPublishedSuccessfully"),
          });
          editJourney(data);
        })
        .catch((err) => {
          return errorsResolver.defaultError;
        });
    },
    [errorsResolver, success, t, editJourney]
  );

  return (
    <JourneyContext.Provider
      value={{
        journeys,
        getJourney,
        addJourney,
        editJourney,
        removeJourney,
        publishJourney,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
};

export const useJourney = () => useContext(JourneyContext);

export default JourneyProvider;
