import { AuthrnticationTokenRequest, SignInRequest, UserType } from "models";
import { AuthErrors } from "pages/auth/errors";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as AuthService from "services/auth";
import * as UserService from "services/user";
import { clearLocalStorage, companyKey, ls, tokenKey, userKey } from "utils";
import { useToast } from "../toast";

type AuthContextType = {
  token?: string | null;
  company?: string | null;
  user: UserType | null;
  codeAccount?: string;
  codeProject?: string;
  isAdmin: boolean;
  isUser: boolean;
  hasAuthenticated: () => Promise<boolean>;
  addEmail: (email: string) => void;
  authenticationToken: (params: AuthrnticationTokenRequest) => Promise<boolean>;
  selectCompany: (currentCompany: string, currentUser: UserType) => void;
  signIn: (params: SignInRequest) => Promise<boolean>;
  signOut: () => void;
  updateUser: (newUser: UserType) => void;
};

type AuthProviderType = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider = ({ children }: AuthProviderType) => {
  const [token, setToken] = useState<string | null>(ls.getItem(tokenKey));
  const [company, setCompany] = useState<string | null>(ls.getItem(companyKey));
  const [codeAccount, setCodeAccount] = useState<string | undefined>();
  const [codeProject, setCodeProject] = useState<string | undefined>();
  const [user, setUser] = useState<UserType | null>(null);
  const { error, warning } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const errorsResolver = useMemo(
    () => new AuthErrors({ error, warning }, t),
    [error, warning, t]
  );

  const isAdmin = useMemo(() => {
    if (user?.claim !== undefined) {
      return (
        user?.claim.some((c) => ["Admin", "Root"].includes(c.name)) ?? false
      );
    } else {
      return false;
    }
  }, [user]);

  const isUser = useMemo(() => {
    if (user?.claim !== undefined) {
      return user?.claim.some((c) => c.name === "User") ?? false;
    } else {
      return false;
    }
  }, [user]);

  const saveData = useCallback(
    (currentUser: UserType, currentToken?: string) => {
      setUser(currentUser);
      ls.setItem(userKey, currentUser._id);

      if (currentToken) {
        setToken(currentToken);
        ls.setItem(tokenKey, currentToken);
      }
    },
    []
  );

  const updateUser = useCallback(
    (newUser: UserType) => {
      saveData(newUser);
    },
    [saveData]
  );

  const deleteData = useCallback(() => {
    setToken(null);
    setCompany(null);
    setUser(null);
    ls.removeItem(tokenKey);
    ls.removeItem(companyKey);
    ls.removeItem(userKey);
  }, []);

  const addEmail = useCallback(
    (email: string) => !user && setUser({ email } as UserType),
    [user]
  );

  const selectCompany = useCallback(
    (currentCompany: string, currentUser: UserType) => {
      if (!company) {
        setCompany(currentCompany);
        saveData(currentUser, currentUser.token);
      }
    },
    [company, saveData]
  );

  useEffect(() => {
    if (!company && user?._id) {
      const selectedCompany = user?.selectedCompany;
      if (selectedCompany) {
        setCompany(selectedCompany._id);
        ls.setItem(companyKey, selectedCompany._id);
        setCodeAccount(selectedCompany?.code_account);
        setCodeProject(selectedCompany?.code_project);
      } else {
        navigate("/authentication/welcome", { replace: true });
      }
    }
  }, [company, user, navigate]);

  const signIn = useCallback(
    async (params: SignInRequest) => {
      return await AuthService.signIn(params)
        .then(({ data }) => {
          saveData(data.user, data.token);
          return true;
        })
        .catch((err) => {
          console.log(err);
          errorsResolver.signIn(err);
          return false;
        });
    },
    [saveData, errorsResolver]
  );

  const authenticationToken = useCallback(
    async (params: AuthrnticationTokenRequest) => {
      return await AuthService.authenticationToken(params)
        .then(({ data }) => {
          saveData(data.user, data.token);
          return true;
        })
        .catch(err => {
          errorsResolver.signIn(err);
          navigate("/authentication", { replace: true });
          return false;
        });
    },
    [ saveData, navigate, errorsResolver]
  );

  const signOut = useCallback(() => {
    deleteData();
    navigate("/authentication", { replace: true });
  }, [deleteData, navigate]);

  const hasAuthenticated = useCallback(async () => {
    if (token && !user) {
      const userId = ls.getItem(userKey);
      let value = false;

      if (userId) {
        await UserService.getUser(userId)
          .then(({ data }) => {
            saveData(data);
            value = true;
          })
          .catch((err) => {
            clearLocalStorage();
            errorsResolver.signIn(err);
          });
      }

      return value;
    }

    return !!token && !!company && !!user;
  }, [token, company, user, errorsResolver, saveData]);

  return (
    <AuthContext.Provider
      value={{
        token,
        company,
        user,
        isAdmin,
        isUser,
        hasAuthenticated,
        authenticationToken,
        addEmail,
        selectCompany,
        signIn,
        signOut,
        updateUser,
        codeAccount,
        codeProject,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
