import { sub } from "date-fns";
import {
  HTMLInputDateTimeType,
  ListConsumptionsType,
  OptionType,
  TimezoneType,
} from "models";

export const consumptionTimezone = "America/Sao_Paulo";

export const dataTimeMapFormats = new Map<HTMLInputDateTimeType, string>([
  ["date", "yyyy-MM-dd"],
  ["datetime-local", "yyyy-MM-dd'T'HH:mm"],
  ["month", "yyyy-MM"],
  ["time", "HH:mm"],
]);

export const formatMaskDate = "dd/MM/yyyy HH:mm:ss";

export const groupByOptions: OptionType<ListConsumptionsType>[] = [
  { value: "month", label: "groupBy.perMonth" },
  { value: "day", label: "groupBy.perDay" },
];

export const periodsOptions: OptionType<number>[] = [
  { value: 1, label: "labels.latest1Day" },
  { value: 7, label: "labels.latest7Days" },
  { value: 15, label: "labels.latest15Days" },
  { value: 30, label: "labels.latest30Days" },
];

export const lastPeriod = periodsOptions[periodsOptions.length - 1].value;

export const currentDate = new Date();
export const lastDate = sub(currentDate, { days: lastPeriod });

export const daysOfTheWeekOptions: OptionType<number>[] = [
  {
    label: "daysOfTheWeek.sun",
    value: 0,
  },
  {
    label: "daysOfTheWeek.mon",
    value: 1,
  },
  {
    label: "daysOfTheWeek.tue",
    value: 2,
  },
  {
    label: "daysOfTheWeek.wed",
    value: 3,
  },
  {
    label: "daysOfTheWeek.thu",
    value: 4,
  },
  {
    label: "daysOfTheWeek.fri",
    value: 5,
  },
  {
    label: "daysOfTheWeek.sat",
    value: 6,
  },
];

export const DDDsOptions: OptionType<number>[] = [
  {
    value: 11,
    label: "11 – São Paulo – SP",
  },
  {
    value: 12,
    label: "12 – São José dos Campos – SP",
  },
  {
    value: 13,
    label: "13 – Santos – SP",
  },
  {
    value: 14,
    label: "14 – Bauru – SP",
  },
  {
    value: 15,
    label: "15 – Sorocaba – SP",
  },
  {
    value: 16,
    label: "16 – Ribeirão Preto – SP",
  },
  {
    value: 17,
    label: "17 – São José do Rio Preto – SP",
  },
  {
    value: 18,
    label: "18 – Presidente Prudente – SP",
  },
  {
    value: 19,
    label: "19 – Campinas – SP",
  },
  {
    value: 21,
    label: "21 – Rio de Janeiro – RJ",
  },
  {
    value: 22,
    label: "22 – Campos dos Goytacazes – RJ",
  },
  {
    value: 24,
    label: "24 – Volta Redonda – RJ",
  },
  {
    value: 27,
    label: "27 – Vila Velha/Vitória – ES",
  },
  {
    value: 28,
    label: "28 – Cachoeiro de Itapemirim – ES",
  },
  {
    value: 31,
    label: "31 – Belo Horizonte – MG",
  },
  {
    value: 32,
    label: "32 – Juiz de Fora – MG",
  },
  {
    value: 33,
    label: "33 – Governador Valadares – MG",
  },
  {
    value: 34,
    label: "34 – Uberlândia – MG",
  },
  {
    value: 35,
    label: "35 – Poços de Caldas – MG",
  },
  {
    value: 37,
    label: "37 – Divinópolis – MG",
  },
  {
    value: 38,
    label: "38 – Montes Claros – MG",
  },
  {
    value: 41,
    label: "41 – Curitiba – PR",
  },
  {
    value: 42,
    label: "42 – Ponta Grossa – PR",
  },
  {
    value: 43,
    label: "43 – Londrina – PR",
  },
  {
    value: 44,
    label: "44 – Maringá – PR",
  },
  {
    value: 45,
    label: "45 – Foz do Iguaçú – PR",
  },
  {
    value: 46,
    label: "46 – Francisco Beltrão/Pato Branco – PR",
  },
  {
    value: 47,
    label: "47 – Joinville – SC",
  },
  {
    value: 48,
    label: "48 – Florianópolis – SC",
  },
  {
    value: 49,
    label: "49 – Chapecó – SC",
  },
  {
    value: 51,
    label: "51 – Porto Alegre – RS",
  },
  {
    value: 53,
    label: "53 – Pelotas – RS",
  },
  {
    value: 54,
    label: "54 – Caxias do Sul – RS",
  },
  {
    value: 55,
    label: "55 – Santa Maria – RS",
  },
  {
    value: 61,
    label: "61 – Brasília – DF",
  },
  {
    value: 62,
    label: "62 – Goiânia – GO",
  },
  {
    value: 63,
    label: "63 – Palmas – TO",
  },
  {
    value: 64,
    label: "64 – Rio Verde – GO",
  },
  {
    value: 65,
    label: "65 – Cuiabá – MT",
  },
  {
    value: 66,
    label: "66 – Rondonópolis – MT",
  },
  {
    value: 67,
    label: "67 – Campo Grande – MS",
  },
  {
    value: 68,
    label: "68 – Rio Branco – AC",
  },
  {
    value: 69,
    label: "69 – Porto Velho – RO",
  },
  {
    value: 71,
    label: "71 – Salvador – BA",
  },
  {
    value: 73,
    label: "73 – Ilhéus – BA",
  },
  {
    value: 74,
    label: "74 – Juazeiro – BA",
  },
  {
    value: 75,
    label: "75 – Feira de Santana – BA",
  },
  {
    value: 77,
    label: "77 – Barreiras – BA",
  },
  {
    value: 79,
    label: "79 – Aracaju – SE",
  },
  {
    value: 81,
    label: "81 – Recife – PE",
  },
  {
    value: 82,
    label: "82 – Maceió – AL",
  },
  {
    value: 83,
    label: "83 – João Pessoa – PB",
  },
  {
    value: 84,
    label: "84 – Natal – RN",
  },
  {
    value: 85,
    label: "85 – Fortaleza – CE",
  },
  {
    value: 86,
    label: "86 – Teresina – PI",
  },
  {
    value: 87,
    label: "87 – Petrolina – PE",
  },
  {
    value: 88,
    label: "88 – Juazeiro do Norte – CE",
  },
  {
    value: 89,
    label: "89 – Picos – PI",
  },
  {
    value: 91,
    label: "91 – Belém – PA",
  },
  {
    value: 92,
    label: "92 – Manaus – AM",
  },
  {
    value: 93,
    label: "93 – Santarém – PA",
  },
  {
    value: 94,
    label: "94 – Marabá – PA",
  },
  {
    value: 95,
    label: "95 – Boa Vista – RR",
  },
  {
    value: 96,
    label: "96 – Macapá – AP",
  },
  {
    value: 97,
    label: "97 – Coari – AM",
  },
  {
    value: 98,
    label: "98 – São Luís – MA",
  },
  {
    value: 99,
    label: "99 – Imperatriz – MA",
  },
];

export const timezonesList: TimezoneType[] = [
  {
    fusoName: "Pacific/Midway",
    fuso: "(GMT-11:00) Midway Island, Samoa",
  },
  {
    fusoName: "Pacific/Honolulu",
    fuso: "(GMT-10:00) Hawaii",
  },
  {
    fusoName: "America/Juneau",
    fuso: "(GMT-9:00) Alaska",
  },
  {
    fusoName: "America/Dawson",
    fuso: "(GMT-8:00) Dawson, Yukon",
  },
  {
    fusoName: "America/Tijuana",
    fuso: "(GMT-8:00) Tijuana, Pacific Time",
  },
  {
    fusoName: "America/Boise",
    fuso: "(GMT-7:00) Mountain Time",
  },
  {
    fusoName: "America/Chihuahua",
    fuso: "(GMT-7:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    fusoName: "America/Phoenix",
    fuso: "(GMT-7:00) Arizona",
  },
  {
    fusoName: "America/Chicago",
    fuso: "(GMT-6:00) Central Time",
  },
  {
    fusoName: "America/Regina",
    fuso: "(GMT-6:00) Saskatchewan",
  },
  {
    fusoName: "America/Mexico_City",
    fuso: "(GMT-6:00) Guadalajara, Mexico City, Monterrey",
  },
  {
    fusoName: "America/Belize",
    fuso: "(GMT-6:00) Central America",
  },
  {
    fusoName: "America/Detroit",
    fuso: "(GMT-5:00) Eastern Time",
  },
  {
    fusoName: "America/Bogota",
    fuso: "(GMT-5:00) Bogota, Lima, Quito",
  },
  {
    fusoName: "America/Caracas",
    fuso: "(GMT-4:00) Caracas, La Paz",
  },
  {
    fusoName: "America/St_Johns",
    fuso: "(GMT-3:30) Newfoundland and Labrador",
  },
  {
    fusoName: "America/Santiago",
    fuso: "(GMT-3:00) Santiago",
  },
  {
    fusoName: "America/Sao_Paulo",
    fuso: "(GMT-3:00) Brasilia",
  },
  {
    fusoName: "America/Argentina/Buenos_Aires",
    fuso: "(GMT-3:00) Buenos Aires, Georgetown",
  },
  {
    fusoName: "America/Godthab",
    fuso: "(GMT-3:00) Greenland",
  },
  {
    fusoName: "Atlantic/Azores",
    fuso: "(GMT-1:00) Azores",
  },
  {
    fusoName: "Atlantic/Cape_Verde",
    fuso: "(GMT-1:00) Cape Verde Islands",
  },
  {
    fusoName: "GMT",
    fuso: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London",
  },
  {
    fusoName: "Africa/Casablanca",
    fuso: "(GMT+0:00) Casablanca, Monrovia",
  },
  {
    fusoName: "Atlantic/Canary",
    fuso: "(GMT+0:00) Canary Islands",
  },
  {
    fusoName: "Europe/Belgrade",
    fuso: "(GMT+1:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    fusoName: "Europe/Sarajevo",
    fuso: "(GMT+1:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    fusoName: "Europe/Brussels",
    fuso: "(GMT+1:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    fusoName: "Europe/Amsterdam",
    fuso: "(GMT+1:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    fusoName: "Africa/Algiers",
    fuso: "(GMT+1:00) West Central Africa",
  },
  {
    fusoName: "Europe/Bucharest",
    fuso: "(GMT+2:00) Bucharest",
  },
  {
    fusoName: "Africa/Cairo",
    fuso: "(GMT+2:00) Cairo",
  },
  {
    fusoName: "Europe/Helsinki",
    fuso: "(GMT+2:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  },
  {
    fusoName: "Europe/Athens",
    fuso: "(GMT+2:00) Athens, Istanbul, Minsk",
  },
  {
    fusoName: "Asia/Jerusalem",
    fuso: "(GMT+2:00) Jerusalem",
  },
  {
    fusoName: "Africa/Harare",
    fuso: "(GMT+2:00) Harare, Pretoria",
  },
  {
    fusoName: "Europe/Moscow",
    fuso: "(GMT+3:00) Moscow, St. Petersburg, Volgograd",
  },
  {
    fusoName: "Asia/Kuwait",
    fuso: "(GMT+3:00) Kuwait, Riyadh",
  },
  {
    fusoName: "Africa/Nairobi",
    fuso: "(GMT+3:00) Nairobi",
  },
  {
    fusoName: "Asia/Baghdad",
    fuso: "(GMT+3:00) Baghdad",
  },
  {
    fusoName: "Asia/Tehran",
    fuso: "(GMT+3:30) Tehran",
  },
  {
    fusoName: "Asia/Dubai",
    fuso: "(GMT+4:00) Abu Dhabi, Muscat",
  },
  {
    fusoName: "Asia/Kabul",
    fuso: "(GMT+4:30) Kabul",
  },
  {
    fusoName: "Asia/Baku",
    fuso: "(GMT+5:00) Baku, Tbilisi, Yerevan",
  },
  {
    fusoName: "Asia/Yekaterinburg",
    fuso: "(GMT+5:00) Ekaterinburg",
  },
  {
    fusoName: "Asia/Karachi",
    fuso: "(GMT+5:00) Islamabad, Karachi, Tashkent",
  },
  {
    fusoName: "Asia/Kolkata",
    fuso: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  {
    fusoName: "Asia/Colombo",
    fuso: "(GMT+5:30) Sri Jayawardenepura",
  },
  {
    fusoName: "Asia/Kathmandu",
    fuso: "(GMT+5:45) Kathmandu",
  },
  {
    fusoName: "Asia/Dhaka",
    fuso: "(GMT+6:00) Astana, Dhaka",
  },
  {
    fusoName: "Asia/Almaty",
    fuso: "(GMT+6:00) Almaty, Novosibirsk",
  },
  {
    fusoName: "Asia/Rangoon",
    fuso: "(GMT+6:30) Yangon Rangoon",
  },
  {
    fusoName: "Asia/Bangkok",
    fuso: "(GMT+7:00) Bangkok, Hanoi, Jakarta",
  },
  {
    fusoName: "Asia/Krasnoyarsk",
    fuso: "(GMT+7:00) Krasnoyarsk",
  },
  {
    fusoName: "Asia/Shanghai",
    fuso: "(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
  },
  {
    fusoName: "Asia/Kuala_Lumpur",
    fuso: "(GMT+8:00) Kuala Lumpur, Singapore",
  },
  {
    fusoName: "Asia/Taipei",
    fuso: "(GMT+8:00) Taipei",
  },
  {
    fusoName: "Australia/Perth",
    fuso: "(GMT+8:00) Perth",
  },
  {
    fusoName: "Asia/Irkutsk",
    fuso: "(GMT+8:00) Irkutsk, Ulaanbaatar",
  },
  {
    fusoName: "Asia/Seoul",
    fuso: "(GMT+9:00) Seoul",
  },
  {
    fusoName: "Asia/Tokyo",
    fuso: "(GMT+9:00) Osaka, Sapporo, Tokyo",
  },
  {
    fusoName: "Australia/Darwin",
    fuso: "(GMT+9:30) Darwin",
  },
  {
    fusoName: "Asia/Yakutsk",
    fuso: "(GMT+10:00) Yakutsk",
  },
  {
    fusoName: "Australia/Brisbane",
    fuso: "(GMT+10:00) Brisbane",
  },
  {
    fusoName: "Asia/Vladivostok",
    fuso: "(GMT+10:00) Vladivostok",
  },
  {
    fusoName: "Pacific/Guam",
    fuso: "(GMT+10:00) Guam, Port Moresby",
  },
  {
    fusoName: "Australia/Adelaide",
    fuso: "(GMT+10:30) Adelaide",
  },
  {
    fusoName: "Australia/Sydney",
    fuso: "(GMT+11:00) Canberra, Melbourne, Sydney",
  },
  {
    fusoName: "Australia/Hobart",
    fuso: "(GMT+11:00) Hobart",
  },
  {
    fusoName: "Asia/Magadan",
    fuso: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
  },
  {
    fusoName: "Asia/Kamchatka",
    fuso: "(GMT+12:00) Kamchatka, Marshall Islands",
  },
  {
    fusoName: "Pacific/Fiji",
    fuso: "(GMT+12:00) Fiji Islands",
  },
  {
    fusoName: "Pacific/Auckland",
    fuso: "(GMT+13:00) Auckland, Wellington",
  },
  {
    fusoName: "Pacific/Tongatapu",
    fuso: "(GMT+13:00) Nuku'alofa",
  },
];
