import IconButton from "components/iconButton";
import styled from "styled-components";

type MenuStyleType = {
  isCollapsed: boolean;
};

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const SideBarConainer = styled.header<MenuStyleType>`
  height: 100%;
  width: ${({ isCollapsed }) => (isCollapsed ? "8.8rem" : "26.2rem")};
  position: relative;
  overflow-x: initial;
  background: var(--background-gray);
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  transition: width ease-in-out 0.5s;
  box-shadow: rgb(0 0 0 / 10%) -4px 0px 10px -2px inset;


  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }

  &:hover > .collapse-button {
    opacity: 1;
    pointer-events: initial;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  a {
    text-decoration: none;
  }
`;

export const NavButton = styled(IconButton).attrs({
  outline: true,
  className: "collapse-button",
})<MenuStyleType>`
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in-out 0.2s;

  position: absolute;
  top: 2.4rem;
  right: -1.2rem;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? "rotate(180deg)" : "initial"};

  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid var(--gray);
  background: var(--white);
  color: var(--text);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LayoutContent = styled.div`
  height: 100%;
  flex: 1;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.section`
  padding: 1.6rem 2.4rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;

  border-bottom: 0.1rem solid var(--gray);

  h1 {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: var(--text-title);
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  left: 0;  
  /* z-index: 999;   */
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const FooterItem = styled.div`
  padding: 3px 26px;
  display: block;
  position: relative;

  a, span {
    display: block;
    margin: 0px;
    text-decoration: none;
    font-family: "Archivo Regular", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-weight: 400;
    color: var(--neutral-3);
  }
  
  span {
    padding: 0 !important;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const InfoContent = styled.div`
  flex: 1;
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const ContentContainer = styled.section`
  width: 100%;
  padding: 1.6rem 2.4rem;
  flex: 1;
`;

export const Anchor = styled.a`
  font-size: 12px;
`;
