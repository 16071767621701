import { ReactComponent as SectorIcon } from "assets/sidebar/sector.svg";
import { Option } from "components/select";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as UserService from "services/user";
import { Errors } from "utils";
import {
  Container,
  IconContent,
  NavSelect,
  SectorContainer,
  SelectLabel,
} from "./styles";

type NavDropdownType = {
  justIcon?: boolean;
};

const NavSector = ({ justIcon }: NavDropdownType) => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation("components");
  const { user, updateUser } = useAuth();
  const { error, warning, success } = useToast();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    [error, warning, t]
  );

  useEffect(() => {
    if (user) setValue(user.selectedOrganization);
  }, [user]);

  const handleChangeOrganization = useCallback(
    async (newValue) => {
      if (value !== newValue)
        await UserService.setOrganization({ selectedOrganization: newValue })
          .then(({ data }) => {
            setValue(newValue);
            updateUser(data);
            success({ description: t("alerts.sectorUpdatedSuccessfully") });
            navigate("/journey", { replace: true });
          })
          .catch(errorsResolver.defaultError);
    },
    [value, errorsResolver, updateUser, success, t, navigate]
  );

  return (
    <Container>
      <IconContent>
        <SectorIcon width="2.1rem" height="2.1rem" />
      </IconContent>
      {!justIcon && (
        <SectorContainer>
          <SelectLabel>{t("sideBar.sector")}</SelectLabel>
          <NavSelect
            fullWidth
            value={value}
            onChange={handleChangeOrganization}
          >
            {user?.organizations.map((org) => {
              return org.companyId === user?.selectedCompany._id &&  (
                <Option key={org._id} value={org._id}>
                  {org.name}
                </Option>
              )
            })}
          </NavSelect>
        </SectorContainer>
      )}
    </Container>
  );
};

export default NavSector;
