import { PlanType } from "models";
import { CompanyErrors } from "pages/company/errors";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as PlanService from "services/plan";

type PlansContextType = {
  plans?: PlanType[];
  currentPlan?: PlanType;
};

type PlansProviderType = {
  children: ReactNode;
};

const PlansContext = createContext<PlansContextType>({} as PlansContextType);

const PlansProvider = ({ children }: PlansProviderType) => {
  const [plans, setPlans] = useState<PlanType[]>();
  const { error, warning } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();

  const errorsResolver = useMemo(
    () => new CompanyErrors({ error, warning }, t),
    [error, warning, t]
  );

  const currentPlan = useMemo(
    () => plans?.find((plan) => plan._id === user?.selectedCompany.plan),
    [plans, user]
  );

  const getPlans = useCallback(async () => {
    await PlanService.getPlans()
      .then(({ data }) => setPlans(data))
      .catch(errorsResolver.defaultError);
  }, [errorsResolver]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  return (
    <PlansContext.Provider value={{ plans, currentPlan }}>
      {children}
    </PlansContext.Provider>
  );
};

export const usePlans = () => useContext(PlansContext);

export default PlansProvider;
