import DialogProvider from "providers/dialog";
import GlobalStyle from "./global.styles";
import AuthProvider from "./providers/auth";
import ToastProvider from "./providers/toast";
import RoutesMap from "./routes";

const App = () => {
  return (
    <ToastProvider position="top-right" theme="light">
      <AuthProvider>
        <DialogProvider>
          <RoutesMap />
        </DialogProvider>
        <GlobalStyle />
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;
