import { AxiosRequestConfig } from "axios";
import {
  CompanyOrganizationRequest,
  OrganizationRequest,
  UserType,
} from "models";
import service from ".";

export const getUsers = async (
  userId: string,
  term?: string,
  config?: AxiosRequestConfig
) =>
  await service.get<UserType[]>("v1/users", {
    ...config,
    params: { userId, term },
  });

export const getUser = async (userId: string, config?: AxiosRequestConfig) =>
  await service.get<UserType>("v1/users/me", { ...config, params: { userId } });

export const setOrganization = async (
  params: OrganizationRequest,
  config?: AxiosRequestConfig
) =>
  await service.put<UserType>("v1/users/select-organization", params, config);

export const setCompanyOrganization = async (
  params: CompanyOrganizationRequest,
  config?: AxiosRequestConfig
) =>
  await service.put<UserType>(
    "v1/users/select-company-organization",
    params,
    config
  );

export const addUser = async (
  companyId: string,
  profileId: string,
  params: UserType,
  config?: AxiosRequestConfig
) =>
  await service.post<UserType>(
    `v1/users`,
    { companyId, profileId, ...params },
    config
  );

export const updateUser = async (
  userId: string,
  params: UserType,
  config?: AxiosRequestConfig
) => await service.put(`v1/users/update/${userId}`, params, config);
