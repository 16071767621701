import { Outlet } from "react-router-dom";
import { ContentContainer } from "./styles";

const MainContainer = () => {
  return (
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  );
};

export default MainContainer;
