import { AxiosRequestConfig } from "axios";
import {
  LoadLayoutResponse,
  LoadLayoutType,
  SegmentsLoadLayoutResponse,
  SegmentsLoadLayoutType,
  GroupResponse,
  GroupType,
  ContatoResponse,
  ContatoType,
  DenyType,
  DenyListResponse,
  InputLinesResponse,
  FilterInputsLinesType
} from "models";
import service from ".";

export const getLoadLayouts = async (
  companyId: string,
  organizationId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<LoadLayoutResponse>(
    `v1/orquestrador/contacts/loadlayout/${companyId}/${organizationId}`,
    config
  );

export const addLoadLayout = async (
  params: Pick<LoadLayoutType, "id_company" | "name" | "layoutFields">,
  config?: AxiosRequestConfig
) =>
  await service.post<LoadLayoutType>(
    `v1/orquestrador/contacts/loadlayout`,
    params,
    config
  );

export const updateLoadLayout = async (
  params: Pick<LoadLayoutType, "_id" | "name" | "layoutFields">,
  config?: AxiosRequestConfig
) =>
  await service.put<LoadLayoutType>(
    `v1/orquestrador/contacts/loadlayout/${params._id}`,
    params,
    config
  );

export const getSegmentsLoadLayouts = async (
  companyId: string,
  organizationId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<SegmentsLoadLayoutResponse>(
    `v1/orquestrador/contacts/segment/${companyId}/${organizationId}`,
    config
  );

export const addSegmentsLoadLayout = async (
  params: SegmentsLoadLayoutType,
  config?: AxiosRequestConfig
) =>
  await service.post<SegmentsLoadLayoutType>(
    `v1/orquestrador/contacts/segment`,
    params,
    config
  );

export const updateSegmentsLoadLayout = async (
  params: SegmentsLoadLayoutType,
  config?: AxiosRequestConfig
) =>
  await service.put<SegmentsLoadLayoutType>(
    `v1/orquestrador/contacts/segment/${params._id}`,
    params,
    config
  );


// *********  GROUP **************** //

export const getGroups = async (
  companyId: string,
  organizationId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<GroupResponse>(
    `v1/orquestrador/contacts/group/${companyId}/${organizationId}`,
    config
  );

export const addGroup = async (
  params: GroupType,
  config?: AxiosRequestConfig
) =>
  await service.post<GroupType>(
    `v1/orquestrador/contacts/group`,
    params,
    config
  );

export const updateGroup = async (
  params: GroupType,
  config?: AxiosRequestConfig
) =>
  await service.put<GroupType>(
    `v1/orquestrador/contacts/group/${params._id}`,
    params,
    config
  );

// *********  Contact **************** //

export const getContacts = async (
  companyId: string,
  organizationId: string,
  config?: AxiosRequestConfig
) => await service.get<ContatoResponse>(
    `v1/orquestrador/contacts/contactlist/${companyId}/${organizationId}`,
    config
  );

export const getInputLines = async (
  inputId: string,
  params: FilterInputsLinesType,
  config?: AxiosRequestConfig
) => await service.get<InputLinesResponse>(
    `v1/orquestrador/contacts/contactlist/inputlines/${inputId}/${params.page}/${params.limit}`,
    config
  );

export const addContact = async (
  params: ContatoType,
  config?: AxiosRequestConfig
) => await service.post<ContatoType>(
    `v1/orquestrador/contacts/contactlist`,
    params,
    config
  );

export const delInputLine = async (
    idContactList: string,
    idInputLine: string,
    config?: AxiosRequestConfig
) => await service.delete(
    `v1/orquestrador/contacts/contactlist/inputline/${idContactList}/${idInputLine}`,
    config
  );

export const addContactList = async (
    params: ContatoType,
    file: File,
    config?: AxiosRequestConfig
  ) => {
    const formData = new FormData();
    formData.append('arquivo', file);
    
    formData.append('description', params.description);
    formData.append('active', params.active.toString());      
    formData.append('deactivationDate', !params.deactivationDate ? '' : params.deactivationDate.toISOString().split('T')[0]);
    formData.append('id_company', params.id_company);
    formData.append('id_organization', params.id_organization);
    formData.append('id_group', params.id_group);   
  
    return await service.post<ContatoType>(
      `v1/orquestrador/contacts/contactlist`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...config,
      }
    );
  };

export const updateContato = async (
  params: ContatoType,
  config?: AxiosRequestConfig
) => await service.put<ContatoType>(
    `v1/orquestrador/contacts/contactlist/${params._id}`,
    params,
    config
  );

export const addNewFile = async (
  params: ContatoType,
  file: File,
  config?: AxiosRequestConfig
) => {
  const formData = new FormData();
  formData.append('arquivo', file);

  return await service.put<ContatoType>(
    `v1/orquestrador/contacts/newfile/${params._id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    }
  );
};
// *********  DenyList **************** //
export const getDenys = async (
  companyId: string,
  organizationId: string,
  config?: AxiosRequestConfig
) => await service.get<DenyListResponse>(
    `v1/orquestrador/contacts/denylist/${companyId}/${organizationId}`,
    config
  );

export const getDenyInputLines = async (
  inputId: string,
  params: FilterInputsLinesType,
  config?: AxiosRequestConfig
) => await service.get<InputLinesResponse>(
    `v1/orquestrador/contacts/denylist/inputlines/${inputId}/${params.page}/${params.limit}`,
    config
  );

export const addDeny = async (
  params: DenyType,
  config?: AxiosRequestConfig
) => await service.post<DenyType>(
    `v1/orquestrador/contacts/denylist`,
    params,
    config
  );

export const delDenyInputLine = async (
    idDenyList: string,
    idInputLine: string,
    config?: AxiosRequestConfig
) => await service.delete(
    `v1/orquestrador/contacts/denylist/inputline/${idDenyList}/${idInputLine}`,
    config
  );

export const addDenyList = async (
    params: DenyType,
    file: File,
    config?: AxiosRequestConfig
  ) => {
    const formData = new FormData();
    formData.append('arquivo', file);
    
    formData.append('description', params.description);
    formData.append('active', params.active.toString());      
    formData.append('deactivationDate', !params.deactivationDate ? '' : params.deactivationDate.toISOString().split('T')[0]);
    formData.append('id_company', params.id_company);
    formData.append('id_organization', params.id_organization);
  
    return await service.post<DenyType>(
      `v1/orquestrador/contacts/denylist`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...config,
      }
    );
  };

export const updateDeny = async (
  params: DenyType,
  config?: AxiosRequestConfig
) => await service.put<DenyType>(
    `v1/orquestrador/contacts/denylist/${params._id}`,
    params,
    config
  );

export const addDenyNewFile = async (
  params: DenyType,
  file: File,
  config?: AxiosRequestConfig
) => {
  const formData = new FormData();
  formData.append('arquivo', file);

  return await service.put<DenyType>(
    `v1/orquestrador/contacts/newdenyfile/${params._id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    }
  );
};

