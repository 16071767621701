import styled from "styled-components";
import { ModalFooter, ModalHeader } from "../modal";

export const CustomModalHeader = styled(ModalHeader)`
  border: initial;
  width: 40rem;
  align-items: flex-start;

  > button {
    height: 3rem;
  }
`;

export const CustomModalFooter = styled(ModalFooter)`
  background-color: var(--white);
`;
