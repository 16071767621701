import styled from "styled-components";

export type NavPercentStyleType = {
  consumption?: number;
  isActive?: boolean;
};

export const Container = styled.li<NavPercentStyleType>`
  width: 100%;
  height: 5.2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.75rem 0.4rem;
  border-radius: 0.4rem;
  background-color: ${({ isActive }) =>
    isActive ? "var(--white)" : "transparent"};

  &:hover {
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
  }

  cursor: pointer;
`;

export const IconContent = styled.div<NavPercentStyleType>`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isActive }) =>
    isActive ? "var(--blue-dark-600)" : "var(--neutral-400)"};
`;

export const PlanInfoContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h2`
  font-size: 1.4rem;
  line-height: 143%;
  text-align: center;
  color: var(--text);
  margin-bottom: 0.2rem;
`;

export const LoadingBar = styled.div<NavPercentStyleType>`
  width: 100%;
  height: 0.4rem;
  background: #dadce3;
  border-radius: 0.4rem;
  margin-bottom: 0.2rem;
  position: relative;
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${(props) => props.color};
    width: ${({ consumption }) => `${consumption}%`};
  }
`;

export const SubInfo = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--neutral-400);
`;
